import { render, staticRenderFns } from "./OrgDetailsPage.vue?vue&type=template&id=75b6d692&scoped=true"
import script from "./OrgDetailsPage.vue?vue&type=script&lang=js"
export * from "./OrgDetailsPage.vue?vue&type=script&lang=js"
import style0 from "./OrgDetailsPage.vue?vue&type=style&index=0&id=75b6d692&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b6d692",
  null
  
)

export default component.exports