import moment from 'moment-timezone';
import { debounce, isArray, isUndefined, isNull } from 'lodash';
import { isObject } from 'class-validator';
import { DateTime } from 'luxon';

import storageHelpers from '@satellite/helpers/storage';
import util from '@satellite/plugins/util';
import { CalendarViewEnum } from '@/enums';
import {
  DateTimeFormats,
  LuxonDateTimeFormats,
  isCapacityChild,
  isCapacityParent,
  removeArrayObjDuplicates,
  AppointmentType
} from '@satellite/../nova/core';

const joinsData = [
  'dock||name,capacityParentId,loadTypeIds,warehouseId',
  'loadType||name,duration_min,direction',
  'user||firstName,lastName,email,phone',
  'user.company||name,scac,mc,usdot,id',
  'org||name,settings',
  'assetVisit||id,isPlanned,phone,createDateTime,visitType',
  'assetVisit.assetVisitEvents||eventType',
  'assetVisit.messageThread||id,unreadMessagesCount'
];

const joinString = joinsData.map(d => `join=${d}`).join('&');

const firstViewTypeEnum = Object.keys(CalendarViewEnum)[0];

function shouldIgnoreReserves(selectedStatuses, shouldFilterReserves) {
  return (
    shouldFilterReserves || (selectedStatuses?.length > 0 && !selectedStatuses.includes('Reserves'))
  );
}

export const viewModes = Object.freeze({
  grid: 'grid',
  list: 'list'
});

// initial state
const state = {
  focus: '',
  viewType: firstViewTypeEnum,
  selectedStatuses: [],
  selectedDocks: [],
  selectedLoadtypes: null,
  selectedWarehouse: {},
  selectedWarehouseTriggers: null,
  calendar: null,
  selectedDate: moment().format(DateTimeFormats.DateDashed),
  events: [],
  companiesKeyedById: {},
  selectedEvent: {},
  mode: 'grid',
  zoomLevel: 30,
  isFetchingEvents: false,
  disabledDateTimesByWarehouse: {},
  fetchingEvents: false,
  useLightGridTheme: false,
  intervalMinutes: 30,
  selectedWarehouseHoops: null,
  shouldShowWeekends: true,
  eventPagination: null
};

// getters
const getters = {
  selectedEvent(state) {
    return state.selectedEvent;
  },
  selectedDockIds(state) {
    let capacityDockIds = [];
    state.selectedDocks?.forEach(dock => {
      capacityDockIds = [
        ...capacityDockIds,
        ...(dock.capacityChildren?.map(childDock => childDock.id) ?? [])
      ];
    });
    const parentDockIds = state.selectedDocks?.map(dock => {
      return dock.id;
    });
    return [...new Set([...parentDockIds, ...capacityDockIds])];
  },
  selectedWarehouseId(state) {
    return state.selectedWarehouse ? state.selectedWarehouse.id : '';
  },
  eventsByWarehouseId: state => warehouseId => {
    return state.events.filter(event => {
      return event.dock.warehouseId === warehouseId;
    });
  },
  warehouseEventsByDock: state => (warehouseId, docks) => {
    return state.events
      .filter(
        event =>
          !(shouldIgnoreReserves(state.selectedStatuses) && event.type === AppointmentType.Reserve)
      )
      .filter(event => isObject(event))
      .filter(event => event.dock?.warehouse?.id === warehouseId && docks.includes(event.dockId));
  },
  dockById: state => dockId => {
    return state.selectedDocks.filter(dock => {
      return dock.id === dockId;
    })[0];
  },
  // Formatted to fit Vuetify calendar expected format
  timeInWarehouseTimezone:
    state =>
    (timestamp, format = DateTimeFormats.DateDashedTime) => {
      return moment(timestamp).tz(state.selectedWarehouse.timezone).format(format);
    },
  momentInTimezone: () => (timestamp, timezone) => {
    return moment(timestamp).tz(timezone);
  },
  docksKeyedById(state) {
    const keyedDocks = {};
    state.selectedDocks
      ?.filter(dock => !isCapacityChild(dock))
      .forEach(dock => {
        dock.readableDockName = dock.name;
        keyedDocks[dock.id] = dock;

        if (isCapacityParent(dock) && dock.capacityChildren) {
          dock.capacityChildren
            .filter(child => isCapacityChild(child))
            .forEach(child => {
              child.readableDockName = util.getReadableChildDockName(
                keyedDocks[child.capacityParentId]
              );
              keyedDocks[child.id] = child;
            });
        }
      });
    return keyedDocks;
  },
  appointmentFormattedAsEvent: (state, getters) => (appointment, context) => {
    return formatAppointmentAsCalendarEvent(appointment, getters, context);
  }
};

// actions
const actions = {
  getEvents: debounce(async function (
    { commit, state, getters, dispatch },
    params = {
      fetchForWarehouse: false,
      limit: null,
      page: null,
      shouldClearPagination: false,
      orderByStart: false,
      shouldFilterReserves: false
    }
  ) {
    commit('setIsFetchingEvents', true);
    let events = [];
    const shouldPaginate = params.limit || params.page;
    try {
      if (state.selectedDocks.length || (params.fetchForWarehouse && state.selectedWarehouse?.id)) {
        let query = `appointment?${joinString}`;
        if (params.limit) {
          query += `&limit=${params.limit}`;
        }
        if (params.page) {
          query += `&page=${params.page}`;
        }
        if (params.orderByStart) {
          query += '&sort=start,ASC';
        }

        if (params.shouldClearPagination) {
          state.eventPagination = null;
        }

        let response = await axios.get(`${query}${getQuery(state, getters, params)}`, {
          selectedDate: state.selectedDate
        });

        if (response && response.data) {
          // Update Event Results Pagination
          if (shouldPaginate) {
            state.eventPagination = {
              page: response.data.page,
              pageCount: response.data.pageCount,
              total: response.data.total
            };
          } else {
            state.eventPagination = null;
          }

          // Make sure an old request is not fullfilled
          if (response.config?.selectedDate === state.selectedDate) {
            const firstAppointment = response.data.data[0];
            const warehouseId = firstAppointment?.dock.warehouseId;

            let warehouse = {};

            if (
              warehouseId &&
              (!state.selectedWarehouse?.id ||
                firstAppointment.dock.warehouseId !== state.selectedWarehouse.id)
            ) {
              warehouse = await dispatch('Warehouses/getWarehouseById', warehouseId, {
                root: true
              });
            }

            const mappedEvents = response.data.data.map(appointment => {
              if (warehouse.id === appointment.dock.warehouseId) {
                appointment.dock.warehouse = warehouse;
              }
              return formatAppointmentAsCalendarEvent(appointment, getters, this);
            });

            if (params?.page <= state.eventPagination?.pageCount && !params.shouldClearPagination) {
              events = removeArrayObjDuplicates([...state.events, ...mappedEvents]);
            } else {
              events = mappedEvents;
            }

            commit('setEvents', events);
          }
        }
      }
    } finally {
      commit('setIsFetchingEvents', false);
    }
  }, 500),
  async getEvent({ state, getters, dispatch }, appointmentId) {
    let response = await axios.get(`appointment/${appointmentId}?${joinString}`);

    let event = null;

    if (response && response.data) {
      const appointment = response.data.data;

      // We must fetch the warehouse data when the selected
      // warehouse is null, or it is different from the selected one.
      // also we update the selected warehouse
      if (
        !state.selectedWarehouse?.id ||
        appointment.dock.warehouseId !== state.selectedWarehouse.id
      ) {
        const warehouse = await dispatch(
          'Warehouses/getWarehouseById',
          appointment.dock.warehouseId,
          { root: true }
        );

        appointment.dock.warehouse = warehouse;
      }
      event = formatAppointmentAsCalendarEvent(appointment, getters, this);
    }

    return event;
  },
  async refreshEventByAppointmentId({ commit, dispatch, state }, data) {
    const event = data.mutation !== 'removeEvent' ? await dispatch('getEvent', data.id) : data;

    if (event !== null) {
      if (data.mutation !== 'removeEvent' && state.selectedEvent?.id === data.id) {
        commit('setSelectedEvent', event);
      }

      commit(data.mutation, event);
    }
  },
  async getCompanies({ commit }) {
    let response = await axios.get('company');

    if (response && response.data) {
      commit('setCompanies', response.data.data);
    }
  },
  async clearSelectedWarehouseData({ commit }) {
    commit('setSelectedWarehouseTriggers', null);
    commit('setSelectedWarehouse', {});
  },
  async getWarehouseTriggers({ commit, state }, { warehouseId }) {
    if (state.selectedWarehouse?.id !== warehouseId) {
      return;
    }
    const response = await axios.get('custom-forms/trigger', {
      params: {
        s: {
          objectId: warehouseId,
          entityName: 'warehouse'
        },
        limit: 100
      }
    });

    // Full join structure
    // trigger: { flow: { fromFrom: { formFields: [{ field: customField }] } } }

    commit('setSelectedWarehouseTriggers', response?.data?.data || []);
  },
  setSelectedDate({ commit, dispatch, state, rootState }, { value, getEvents }) {
    if (value) {
      commit('setSelectedDate', value);
      if (getEvents || getEvents === undefined) {
        dispatch('getEvents');
      }
      setCalendarData(rootState.Auth.me.id, 'selectedDate', value, state);
    }
  },
  setViewType({ commit, dispatch, state, rootState }, { value, getEvents }) {
    commit('setViewType', value);
    if (getEvents || getEvents === undefined) {
      dispatch('getEvents');
    }
    setCalendarData(rootState.Auth.me.id, 'viewType', value, state);
  },
  setMode({ commit, dispatch, state, rootState }, { value, getEvents }) {
    commit('setMode', value);
    if (getEvents || getEvents === undefined) {
      dispatch('getEvents');
    }
    setCalendarData(rootState.Auth.me.id, 'mode', value, state);
  },
  setSelectedStatuses({ commit, state, rootState, dispatch }, statuses) {
    commit('setSelectedStatuses', statuses);
    dispatch('getEvents');
    setCalendarData(rootState.Auth.me.id, 'selectedStatuses', statuses, state);
  },
  setSelectedLoadtypes({ commit, state, rootState }, loadtypes) {
    commit('setSelectedLoadtypes', loadtypes);
    setCalendarData(rootState.Auth.me.id, 'selectedLoadtypes', loadtypes, state);
    state.eventPagination = null;
  },
  setSelectedDocks({ commit, state, rootState }, docks) {
    commit(
      'setSelectedDocks',
      docks.filter(dock => dock.isActive)
    );
    setCalendarData(rootState.Auth.me.id, 'selectedDocks', docks, state);
    state.eventPagination = null;
  },
  setSelectedWarehouse({ commit, state, rootState, dispatch }, warehouse) {
    commit('setSelectedWarehouse', warehouse);
    setCalendarData(rootState.Auth.me.id, 'selectedWarehouse', warehouse, state);
    state.eventPagination = null;

    if (warehouse?.id) {
      dispatch('getWarehouseTriggers', { warehouseId: warehouse.id });
    } else {
      commit('setSelectedWarehouseTriggers', []);
    }
  },
  setZoomLevel({ commit, state, rootState }, zoom) {
    commit('setZoomLevel', zoom);
    setCalendarData(rootState.Auth.me.id, 'zoomLevel', zoom, state);
  },
  setUseLightGridTheme({ commit, state, rootState }, useLightGridTheme) {
    commit('setUseLightGridTheme', useLightGridTheme);
    setCalendarData(rootState.Auth.me.id, 'useLightGridTheme', useLightGridTheme, state);
  },
  setIntervalMinutes({ commit, state, rootState }, intervalMinutes) {
    commit('setIntervalMinutes', intervalMinutes);
    setCalendarData(rootState.Auth.me.id, 'intervalMinutes', intervalMinutes, state);
  },
  async setSelectedWarehouseHoops({ state, commit }, { warehouseId, startDate, endDate }) {
    const response = await axios.post(`warehouse/${warehouseId}/get-hours-of-operation`, {
      start: startDate,
      end: endDate
    });

    if (response && response.data) {
      const hoopsByDock = {};
      response.data.data.forEach(dockAvailability => {
        hoopsByDock[dockAvailability.dockId] = dockAvailability.openIntervals.map(openInterval => {
          return {
            start: moment.tz(openInterval.start, state.selectedWarehouse.timezone).toDate(),
            end: moment.tz(openInterval.end, state.selectedWarehouse.timezone).toDate()
          };
        });
      });
      commit('setSelectedWarehouseHoops', hoopsByDock);
    }
  },
  setShouldShowWeekends({ commit, dispatch, state, rootState }, { value, getEvents }) {
    commit('setShouldShowWeekends', value);
    if (getEvents || getEvents === undefined) {
      dispatch('getEvents');
    }
    setCalendarData(rootState.Auth.me.id, 'shouldShowWeekends', value, state);
  }
};

// mutations
const mutations = {
  setSelectedDate(state, date) {
    state.selectedDate = date;
  },
  setSelectedDocks(state, docks) {
    state.selectedDocks = docks;
  },
  setSelectedLoadtypes(state, loadtypes) {
    state.selectedLoadtypes = loadtypes;
  },
  setSelectedStatuses(state, statuses) {
    state.selectedStatuses = statuses;
  },
  setSelectedWarehouse(state, warehouse) {
    state.selectedWarehouse = warehouse;
  },
  setSelectedWarehouseTriggers(state, triggers) {
    state.selectedWarehouseTriggers = triggers;
  },
  setViewType(state, type) {
    state.viewType = type;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setCalendar(state, calendar) {
    state.calendar = calendar;
  },
  setFocus(state, focus) {
    state.focus = focus;
  },
  setUseLightGridTheme(state, useLightGridTheme) {
    state.useLightGridTheme = useLightGridTheme;
  },
  setIntervalMinutes(state, intervalMinutes) {
    state.intervalMinutes = intervalMinutes;
  },
  setEvents(state, events) {
    events?.forEach(e => enrichEventWithState(e));
    state.events = events;
  },
  setCompanies(state, companies) {
    let companiesKeyedById = {};
    companies.forEach(company => {
      companiesKeyedById[company.id] = company;
    });
    state.companiesKeyedById = companiesKeyedById;
  },
  insertEvent(state, newEvent) {
    enrichEventWithState(newEvent);
    let index = state.events.findIndex(event => event.id === newEvent.id);
    let events = [...state.events];
    if (index >= 0) {
      events[index] = newEvent;
    } else {
      const viewType = state.viewType.toLowerCase();
      const selectedDate = moment.tz(state.selectedDate, newEvent.dock.warehouse.timezone);
      const start = selectedDate.clone().startOf(viewType);
      const end = selectedDate.clone().endOf(viewType);
      const eventStart = moment.tz(newEvent.start, newEvent.dock.warehouse.timezone);

      // Only add to events if it fits current selected date/viewtype condition
      if (start.isSameOrBefore(eventStart) && end.isSameOrAfter(eventStart)) {
        events.push(newEvent);
      }
    }

    state.events = events;
  },
  removeEvent(state, eventToRemove) {
    state.events = state.events.filter(event => event.id !== eventToRemove.id);
  },
  setSelectedEvent(state, event) {
    enrichEventWithState(event);
    state.selectedEvent = event;
  },
  setZoomLevel(state, zoom) {
    state.zoomLevel = zoom;
  },
  setIsFetchingEvents(state, isFetchingEvents) {
    state.isFetchingEvents = isFetchingEvents;
  },
  setDisabledDateTimesByWarehouse(state, data) {
    state.disabledDateTimesByWarehouse = data;
  },
  setSelectedWarehouseHoops(state, data) {
    state.selectedWarehouseHoops = data;
  },
  setShouldShowWeekends(state, shouldShowWeekends) {
    state.shouldShowWeekends = shouldShowWeekends;
  }
};

function enrichEventWithState(event) {
  if (event) {
    if (!event.dock.warehouse && state.selectedWarehouse?.id === event.dock.warehouseId) {
      event.dock.warehouse = state.selectedWarehouse;
    }
    event.org = state.selectedWarehouse.org;
  }
}

function makeInitialCalendarData(state) {
  return {
    viewType: state.viewType,
    selectedStatuses: state.selectedStatuses,
    selectedDocks: state.selectedDocks,
    selectedWarehouse: state.selectedWarehouse,
    selectedLoadtypes: state.selectedLoadtypes,
    // selectedDate: state.selectedDate,
    mode: state.mode,
    zoomLevel: state.zoomLevel
  };
}

function setCalendarData(userId, key, data, state) {
  const novaCalendarKey = storageHelpers.makeUserBoundedKey('nova_calendar', userId);

  if (!localStorage.getItem(novaCalendarKey)) {
    localStorage.setItem(
      novaCalendarKey,
      JSON.stringify({
        ...makeInitialCalendarData(state),
        calendar: null
      })
    );
  }

  let currentStorage = JSON.parse(localStorage.getItem(novaCalendarKey));
  currentStorage[key] = data;
  if (key !== 'selectedDate') {
    localStorage.setItem(novaCalendarKey, JSON.stringify({ ...currentStorage }));
  }
}

function formatAppointmentAsCalendarEvent(appointment, getters, context) {
  const event = { ...appointment };
  enrichEventWithState(event);

  const warehouseTimezone = event.dock.warehouse.timezone;

  if (!warehouseTimezone) {
    throw new Error(`Warehouse does not have a timezone set`);
  }

  const startMomentBase = Object.freeze(
    getters['momentInTimezone'](event.start, warehouseTimezone)
  );
  const endMomentBase = Object.freeze(getters['momentInTimezone'](event.end, warehouseTimezone));
  const dateTimeFormat = DateTimeFormats.DateDashedTime;

  const startDateTime = DateTime.fromISO(event.start, { zone: event.dock.warehouse.timezone });
  const endDateTime = DateTime.fromISO(event.end, { zone: event.dock.warehouse.timezone });

  event.utcStart = event.start;
  event.utcEnd = event.end;
  event.startDateTime = startDateTime;
  event.endDateTime = endDateTime;
  event.startTimezone = startDateTime.toFormat('ZZZZ');
  event.endTimezone = endDateTime.toFormat('ZZZZ');
  event.isInDSTChange = startDateTime.offset !== endDateTime.offset;
  event.isRecurringAppointment = context.$app.novaCore.isRecurringAppointment(appointment);
  event.isRecurringParent = context.$app.novaCore.isRecurringAppointmentParent(appointment);
  event.isRecurringChild = context.$app.novaCore.isRecurringAppointmentChild(appointment);
  event.parentDockId = appointment.dock?.capacityParentId;
  event.startWeekday = startMomentBase.isoWeekday();
  event.endWeekday = endMomentBase.isoWeekday();
  event.startTime = context.$app.novaCore.formatDateTimeWithMilitarySupport(
    startMomentBase.format(),
    startMomentBase.tz(),
    LuxonDateTimeFormats.Extended12HrTimeAMPM,
    context.$app.$isMilitaryTimeEnabled(context.$app.$org),
    LuxonDateTimeFormats.Extended24HrTime
  );
  event.endTime = context.$app.novaCore.formatDateTimeWithMilitarySupport(
    endMomentBase.format(),
    endMomentBase.tz(),
    LuxonDateTimeFormats.Extended12HrTimeAMPM,
    context.$app.$isMilitaryTimeEnabled(context.$app.$org),
    LuxonDateTimeFormats.Extended24HrTime
  );
  event.startTime24 = startMomentBase.clone().format(DateTimeFormats.Extended24HrTime);
  event.startDate = startMomentBase.clone().format(DateTimeFormats.DateDashed);
  event.weekday = startMomentBase.clone().weekday();
  event.start = startMomentBase.clone().format(dateTimeFormat);
  event.end = endMomentBase.clone().format(dateTimeFormat);
  event.startClockSpan = startMomentBase
    .clone()
    .format(DateTimeFormats.Extended24HrTimeLeadingZeroHour);
  event.endClockSpan = endMomentBase
    .clone()
    .format(DateTimeFormats.Extended24HrTimeLeadingZeroHour);
  event.color = appointment.type === 'Reserve' ? 'reserve' : appointment.status.toLowerCase();
  event.category = appointment.dock.id;
  event.scac = event.user.company?.scac;
  event.isReserve = context.$app.novaCore.isReserve(appointment);
  event.isRequested = context.$app.novaCore.isRequested(appointment.status);
  event.refNumLabel = getRefNumberLabel(appointment, context);
  event.readableRefNumber = truncateString(appointment.refNumber, null, 20);
  event.companyName = truncateString(appointment.user?.company?.name, 'No carrier');
  event.formattedTags = getFormattedTags(appointment, context);
  event.tagStr = getTagStr(event);
  event.customFieldStr = getCustomFieldStr(appointment, context);
  event.readableDockName = truncateString(appointment.dock.name);
  event.readableLoadTypeName = truncateString(appointment.loadType?.name, null, 20);

  // This is necessary to make sure the appointment will fit on the day view
  // since it ends at the next day from the selected date.
  if (endMomentBase.get('hour') === 0 && endMomentBase.get('minute') === 0) {
    event.end = moment(endMomentBase).subtract(1, 'minutes').format(dateTimeFormat);
  }

  const apptDock = context.getters['Calendar/docksKeyedById'][appointment.dockId];
  if (isCapacityChild(apptDock) && !isCapacityParent(apptDock)) {
    event.readableDockName = context.$app.util.getReadableChildDockName(
      context.getters['Calendar/docksKeyedById'][apptDock.capacityParentId]
    );
  }

  event.gridTileConfig = event.dock?.warehouse?.settings?.gridTileConfig ?? [];
  event.gridTileFields = [];
  if (isArray(event.customFields)) {
    const safeClone = val => {
      if (isUndefined(val)) {
        return val;
      }
      if (isNull(val)) {
        return val;
      }
      return context.$app.novaCore.deepClone(val);
    };

    for (const field of event.customFields) {
      event.gridTileFields.push({
        type: field.type,
        label: field.label,
        value: safeClone(field.value)
      });
    }
  }

  return event;
}

/**
 *
 * @param {Event} event
 * @returns {string}
 */
function getTagStr(event) {
  return `${event?.formattedTags?.tagStr}  ${
    event.formattedTags.hiddenTagCount ? `+ ${event.formattedTags.hiddenTagCount} more` : ''
  }`;
}

function getRefNumberLabel(event, context) {
  return context.$app.$getSettingValue('referenceNumberDisplayName', event?.org?.settings || null);
}

/**
 * Formats tags for event view
 * @param {Appointment} appointment
 * @param context
 * @returns {{hiddenTagCount: number, renderableTags: [object], tagStr: string}}
 */
function getFormattedTags(appointment, context) {
  let renderableTags = [];
  let tagStr = ''; // Used for checking the length / truncation
  let hiddenTagCount = 0;

  if (appointment.tags?.length > 0) {
    const allTags = context.$app.novaCore.deepClone(appointment.tags);
    const customTags = appointment.tags.filter(t =>
      Boolean(context.$app.util.getCustomTagData(context.$app.$org.customTags, t))
    );
    // Sort custom tags to front but keep the original order overall
    const tags = [...new Set([...customTags, ...allTags])];
    // TODO part of all of this logic is duplicated in the the eventtooltip comp - abstract
    tags.map(tag => {
      let newTagStr = tagStr ? `${tagStr}, #${tag}` : `#${tag}`;
      if (!context.$app.novaCore.shouldTruncateString(newTagStr, 35)) {
        const td = context.$app.util.getCustomTagData(context.$app.$org.customTags, tag);
        tagStr += tagStr ? `, #${tag}` : `#${tag}`;
        renderableTags.push({
          name: td?.name || tag,
          color: td?.color || null,
          textColor: td?.textColor || null
        });
      } else {
        hiddenTagCount++;
      }
    });
  }

  return {
    tagStr,
    renderableTags,
    hiddenTagCount
  };
}

function getCustomFieldStr(appointment, context) {
  let fieldsStr = '';
  const shouldGenerateCustomFieldStr =
    appointment.customFields && Object.keys(appointment.customFields)?.length > 0;
  if (shouldGenerateCustomFieldStr) {
    // every function used here so we can break the loop with falsy value
    Object.keys(appointment.customFields).every(index => {
      const field = appointment.customFields[index];
      if (field.value) {
        const val = context.$app.novaCore.truncateString(
          context.$app.novaCore.getCustomFieldFormattedValue(field, {
            [context.$app.novaCore.CustomFieldType.Timestamp]: {
              timezone: appointment?.dock?.warehouse?.timezone,
              formatAsMilitary: context.$app.$isMilitaryTimeEnabled(context.$app.$org)
            }
          }),
          15
        );
        const label = context.$app.novaCore.truncateString(field.label, 10);
        fieldsStr = fieldsStr ? `${fieldsStr}, ${label}: ${val}` : `${label}: ${val}`;
      }
      if (index == 1) {
        // Break loop
        return false;
      }
      return true;
    });
  }
  return fieldsStr;
}

/**
 * Truncates a string for visualization purposes.
 * @param {string} str
 * @param {string | null} missingMessage
 * @param {number} limit
 * @returns {string}
 */
function truncateString(str, missingMessage = null, limit = 12) {
  if (str?.length > limit + 3) {
    return str.slice(0, limit) + '...';
  }
  return str || missingMessage;
}

/**
 * Gets query params for getEvents action
 * @param state
 * @param getters
 * @returns {string}
 */
function getQuery(state, getters, params) {
  const unit = `${state.viewType.toLowerCase()}`;
  const warehouseTimezone = state.selectedWarehouse?.timezone;

  const momentBase = warehouseTimezone
    ? moment(state.selectedDate).tz(warehouseTimezone, true).startOf(unit)
    : moment(state.selectedDate).startOf(unit);

  const startDate = momentBase.clone().toISOString();
  const endDate = momentBase.clone().add(1, `${unit}s`).toISOString();

  let todayStart, todayEnd;
  if (warehouseTimezone) {
    todayStart = moment().tz(warehouseTimezone).startOf('day').toISOString();
    todayEnd = moment().tz(warehouseTimezone).endOf('day').toISOString();
  }

  const query = {
    $and: []
  };

  const coreFilter = {
    $or: [
      {
        start: {
          $between: [startDate, endDate]
        }
      },
      {
        end: {
          $between: [startDate, endDate]
        }
      }
    ]
  };

  if (warehouseTimezone && !params?.dismissCheckInNotifications) {
    coreFilter.$or.push({
      // Self Check-in Notifications: Make sure current date is always fetched
      $and: [
        {
          isCheckedInByCarrier: true
        },
        {
          start: {
            $between: [todayStart, todayEnd]
          }
        }
      ]
    });
  }

  query.$and.push(coreFilter);

  query.$and.push({
    'dock.warehouseId': {
      $eq: state.selectedWarehouse.id
    }
  });

  if (state.selectedDocks.length) {
    query.$and.push({
      dockId: {
        $in: getters.selectedDockIds
      }
    });
  }

  if (state.selectedLoadtypes !== null) {
    // We need to add the is null to show reserves,
    // they are appointments but without a loadtype

    const ltCondition = [
      {
        loadTypeId: {
          $is: null
        }
      }
    ];

    if (state.selectedLoadtypes.length > 0) {
      ltCondition.push({
        loadTypeId: {
          $in: state.selectedLoadtypes
        }
      });
    }

    query.$and.push({
      $or: ltCondition
    });
  }

  if (shouldIgnoreReserves(state.selectedStatuses, params?.shouldFilterReserves)) {
    query.$and.push({
      type: {
        $ne: AppointmentType.Reserve
      }
    });
  }

  const queryString = JSON.stringify(query);

  return `&s=${queryString}`;
}

export default {
  namespaced: true,
  name: 'Calendar',
  state,
  getters,
  actions,
  mutations
};
