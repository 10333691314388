<template>
  <section>
    <form-base>
      <template #form>
        <v-card elevation="0" class="my-6" v-if="warehouses?.length === 0">
          <v-card-title>
            <v-icon small class="mr-2">mdi-alert</v-icon>
            No warehouses found for this org
          </v-card-title>
        </v-card>
        <v-form ref="form" v-on:submit.prevent @keyup.native.enter="submit" v-else>
          <v-select
            required
            :items="featuresOptions"
            v-model="selectedFeature"
            label="Select a feature to enable/disable for this org warehouses"
            item-text="text"
            item-value="value"></v-select>

          <v-alert type="warning" class="darken-2" v-if="isCheckInFeature">
            <p>
              In order to fully enable the check-in feature, please install the feature and set the
              coordinates.
            </p>
            <strong>The coordinates can be set individually for each warehouse.</strong>
          </v-alert>

          <v-card
            elevation="1"
            class="my-4"
            v-if="selectedFeature && warehouses.length > 0"
            :style="{ 'max-height': isCheckInFeature ? '300px' : '400px' }">
            <v-card-title>
              <small>{{ selectedFeatureDescription }}</small>
            </v-card-title>
            <v-list>
              <template v-slot:activator>
                <v-list-item-title><strong>Warehouses feature status</strong></v-list-item-title>
                <v-list-item-action>
                  ({{ warehouseTriggers[selectedFeature]?.length }})
                </v-list-item-action>
              </template>
              <v-list-item>
                <v-row class="my-0 py-0">
                  <v-col>
                    <strong>
                      <v-icon small class="mr-2">mdi-warehouse</v-icon>
                      Warehouse
                    </strong>
                  </v-col>
                  <v-col class="text-center" align-self="end">
                    <v-icon small class="mr-2">mdi-form-select</v-icon>
                    <strong>Status</strong>
                  </v-col>
                  <v-col class="text-center" v-if="isCheckInFeature" align-self="end">
                    <v-icon small class="mr-2">mdi-earth</v-icon>
                    <strong>Coordinates set?</strong>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item
                v-for="warehouse of warehouseTriggers[selectedFeature]"
                :key="warehouse.id">
                <v-list-item-content>
                  <v-row>
                    <v-col>
                      {{ warehouse.name }}
                    </v-col>
                    <v-col
                      class="text-center"
                      align-self="end"
                      :title="
                        isCheckInFeature && !isGeolocationSet(warehouse.id)
                          ? 'Disabled, coordinates must be set'
                          : ''
                      ">
                      <v-btn
                        icon
                        @click="selectedWarehouseIdToggle = warehouse.id"
                        :disabled="isCheckInFeature && !isGeolocationSet(warehouse.id)">
                        <v-icon
                          large
                          :title="
                            isFeatureInstalled(warehouse.id, selectedFeature)
                              ? 'Installed'
                              : 'Not Installed'
                          "
                          :color="
                            isFeatureInstalled(warehouse.id, selectedFeature) ? 'primary' : 'grey'
                          ">
                          {{
                            isFeatureInstalled(warehouse.id, selectedFeature)
                              ? 'mdi-toggle-switch'
                              : 'mdi-toggle-switch-off'
                          }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="text-center" v-if="isCheckInFeature" align-self="end">
                      <v-btn icon @click="toggleGeolocation(warehouse, selectedFeature)">
                        <v-icon
                          large
                          :title="isGeolocationSet(warehouse.id) ? 'Yes' : 'No'"
                          :color="isGeolocationSet(warehouse.id) ? 'primary' : 'grey'">
                          {{
                            isGeolocationSet(warehouse.id) ? 'mdi-map-marker' : 'mdi-map-marker-off'
                          }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-form>
      </template>
      <template #form-actions>
        <action-group
          @cancel="cancel"
          confirm-icon="update"
          confirm-label="Install for all warehouses"
          :disable-confirm="!selectedFeature"
          @confirm="submit"></action-group>
      </template>
    </form-base>
    <v-dialog :value="selectedWarehouseIdToggle" width="500">
      <v-toolbar color="primary" dense flat>
        <v-icon left color="white">mdi-help-circle-outline</v-icon>
        <v-toolbar-title class="white--text" v-text="'Confirm?'" />
      </v-toolbar>
      <v-card>
        <v-card-text>
          <p class="my-4 font-size-medium">
            Are you sure to
            {{
              isFeatureInstalled(selectedWarehouseIdToggle, selectedFeature)
                ? 'uninstall'
                : 'install'
            }}
            the
            <strong>{{ selectedFeatureTitle }}</strong>
            feature for this warehouse?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="grey" @click="selectedWarehouseIdToggle = null">Nevermind</v-btn>
          <v-btn text color="primary" @click="toggleFeature">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-org-warehouse-geolocation-dialog
      v-if="editingWarehouse"
      external-activator
      :warehouse="editingWarehouse"
      @close="editingWarehouse = null"
      @updated="setWarehouseTriggers"
      :show-dialog="Boolean(editingWarehouse?.id)"></edit-org-warehouse-geolocation-dialog>
  </section>
</template>

<script>
import { isDefined } from '@vueuse/core';
import { isUUID } from 'class-validator';
import { CustomFormsFeatures, CustomFormsFeaturesData } from '@nova/core';

export default {
  props: {
    orgId: {
      type: String,
      required: true
    },
    orgName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedFeature: '',
      selectedWarehouseIdToggle: null,
      warehouseTriggers: {},
      warehouses: [],
      editingWarehouse: null
    };
  },
  computed: {
    isCheckInFeature() {
      return this.selectedFeature === CustomFormsFeatures.CheckIn;
    },
    features() {
      return Object.keys(CustomFormsFeaturesData)
        .map(key => key)
        .filter(
          key =>
            ![CustomFormsFeatures.BookLoadType, CustomFormsFeatures.NavTracObservation].includes(
              key
            )
        );
    },
    featuresOptions() {
      return this.features
        .map(feature => ({
          text: this.featureData(feature)?.title,
          value: feature
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    selectedFeatureDescription() {
      return this.featureData(this.selectedFeature)?.description;
    },
    selectedFeatureTitle() {
      return this.featureData(this.selectedFeature)?.title;
    },
    toggleAction() {
      if (!(this.selectedWarehouseIdToggle && this.selectedFeature)) {
        return;
      }
      return this.isFeatureInstalled(this.selectedWarehouseIdToggle, this.selectedFeature)
        ? 'uninstall'
        : 'install';
    }
  },
  methods: {
    featureData(feature) {
      return CustomFormsFeaturesData[feature];
    },
    isGeolocationSet(warehouseId) {
      const geolocation = this.warehouses.find(w => w.id === warehouseId)?.geolocation;
      return isDefined(geolocation?.latitude) && isDefined(geolocation?.longitude);
    },
    isFeatureInstalled(warehouseId, feature) {
      return this.warehouseTriggers[feature]?.find(w => w.id === warehouseId)?.isInstalled;
    },
    async toggleFeature() {
      if (!isUUID(this.selectedWarehouseIdToggle)) {
        this.notify('Invalid warehouse', 'error');
        return;
      }

      const warehouseId = this.selectedWarehouseIdToggle;

      this.$emit('loading', true);

      try {
        let url = `/org/${this.orgId}/features/${this.selectedFeature}`;
        if (this.toggleAction === 'uninstall') {
          url += '/uninstall';
        }
        const response = await axios.post(`${url}?warehouseId=${warehouseId}`);
        if (response?.data?.ok === true) {
          this.notify(
            `${this.selectedFeatureTitle} was ${this.toggleAction}ed for this warehouse`,
            'success'
          );
          await this.setWarehouseTriggers();
        }
      } finally {
        this.$emit('loading', false);
        this.selectedWarehouseIdToggle = null;
      }
    },
    toggleGeolocation(warehouse) {
      this.editingWarehouse = warehouse;
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit('loading', true);

      try {
        const response = await axios.post(`/org/${this.orgId}/features/${this.selectedFeature}`);
        if (response?.data?.ok === true) {
          this.notify(`${this.selectedFeatureTitle} was installed at ${this.orgName}`, 'success');
        }
      } finally {
        this.$emit('loading', false);
        this.$emit('close');
      }
    },
    cancel() {
      this.$emit('close');
    },
    async getTriggers() {
      const response = await axios.get('/custom-forms/trigger', {
        params: {
          s: { orgId: this.orgId },
          limit: 10000
        }
      });

      return response?.data?.data || [];
    },
    async getWarehouses() {
      const response = await axios.get('/warehouse', {
        params: {
          s: { orgId: this.orgId },
          limit: 1000
        }
      });

      return response?.data?.data || [];
    },
    async setWarehouseTriggers() {
      this.$emit('loading', true);

      const triggers = await this.getTriggers();
      this.warehouses = await this.getWarehouses();

      this.features.forEach(feature => {
        const featureTriggers = triggers.filter(t => t.feature === feature);

        this.warehouseTriggers[feature] = this.warehouses
          .map(warehouse => {
            const warehouseTriggers = featureTriggers.filter(t => t.objectId === warehouse.id);
            return {
              id: warehouse.id,
              name: warehouse.name,
              isInstalled: warehouseTriggers.length > 0 && warehouseTriggers.every(t => t.flow),
              ...warehouse
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      });

      this.$emit('loading', false);
    }
  },
  async mounted() {
    await this.setWarehouseTriggers();
  }
};
</script>
